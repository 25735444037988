<template>
  <div class="text-right">
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import selectfalse from '../../assets/selectf.png'
import selecttrue from '../../assets/selectt.png'
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      selectShow: false,
      radio: 0,
      activeIcon: selectfalse,
      inactiveIcon: selecttrue,
      indexs: 0,
      defaultPhoneHeight: null,//屏幕默认高度
      nowPhoneHeights: null, //屏幕现在的高度
      navhide: false,//手机软键盘是否被唤起
      userInfo: [],
      exchangeRatio: [15, 20, 23, 25, 28, 30],
      familyIncomeData: [],
      exchange: [],
      ruleShow: false,
      SuccessfulShow: false,
      exchangeShow: false,
      guanbiShow: false,
      bindingShow: false,
      frozen: true,
      money: '',
      freezeAmount: 0,
      token: null,
      uid: null,
      headers: null,
      // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOamcwIiwieHl6IjoiWmpsalpEUTVZakpqT0daaU5HTmlNV0ZpTnpWbFkyWmlNell3WmpnNVlqVT0iLCJleHAiOjE5MTU3NTg2MDl9.woHxGGW4vyRPKsQelq2VVlxyccBzFPK1vaIujRSFY-o',
      // uid: 684,
      // headers: [
      //   'Content-Type:' + 'application/json; charset=utf-8',
      //   'deviceId:' + 'f9cd49b2c8fb4cb1ab75ecfb360f89b5',
      //   'lang:' + 'zh_CN',
      //   'version:' + '2.0.2',
      //   'os:' + '1',
      //   'model:' + 'M2011K2C',
      //   'fid:' + '0',
      // ],
      placeholders: '',
      minMoney: 10
    };
  },
  created() {
    // this.$completes.loadErudaJs()
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
  },
  mounted() {
    //调取原生方法
    // this.$i18n.locale = 'en'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    document.getElementsByTagName("html")[0].style.backgroundColor = "#ffffff";
    this.defaultPhoneHeight = window.innerHeight
    window.onresize = () => {
      this.nowPhoneHeights = window.innerHeight
    }
  },

  filters: {
    //数字格式化
    playvolume: function (arg) {
      if (arg > 1000) {
        return arg.toLocaleString()
      }
      else {
        return arg
      }
    },
  },

  methods: {
    //跳转提现信息
    setBinding() {
      this.$completes.complete("setBinding");
      this.bindingShow = false
    },

    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('familyIncome.familyIncometitle')
      this.familyPocketInfo()
    },
    //提现金额限制只能输入一位小数点
    oninput(val, limit = 0) {
      val = val.replace(/[^\d.]/g, ""); //保留数字
      val = val.replace(/^00/, "0."); //开头不能有两个0
      // val = val.replace(/^\./g, "0."); //开头为小数点转换为0.
      val = val.replace(/\.{2,}/g, "."); //两个以上的小数点转换成一个
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //只保留一个小数点
      /^0\d+/.test(val) ? val = val.slice(1) : ''; //两位以上数字开头不能为0
      const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
      const reg = new RegExp(str)
      if (limit === 0) {
        // 不需要小数点
        val = val.replace(reg, '$1')
      } else {
        // 通过正则保留小数点后指定的位数
        val = val.replace(reg, '$1.$2')
      }
      return val
    },

    //获取家族族长收益信息
    familyPocketInfo() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/trade/withdraw/familyPocketInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.familyIncomeData = data.data
          this.exchange = data.data.exchange
          this.freezeAmount = data.data.freezeAmount
          this.minMoney = data.data.exchange.minMoney
          this.placeholders = this.$t('familyIncome.exchange_lowest').replace('10', data.data.exchange.minMoney)
        }
        else {
          Toast(data.message || this.$t('failed'))
        }
      })
    },

    //获取家族族长收益信息
    familyWithdraw() {
      let that = this
      let paramObjs = {
        option: 'post',
        host: this.$serviceIp + 'api/trade/withdraw/familyWithdraw?money=' + this.money + '&token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.ruleShow = false
          this.SuccessfulShow = true
          this.money = ''
          this.familyPocketInfo()
        }
        else {
          Toast(data.message || this.$t('failed'))
        }
      })
    },

    //弹起提现框
    Withdraw() {
      if (this.familyIncomeData.bind == 1) {
        this.ruleShow = true
        // this.$nextTick(() => { //自动获取焦点 element组件autofocus失效
        //   this.$refs['atlas-name-input'].$refs.input.focus()
        // })
      }
      else {
        this.bindingShow = true
      }
    },

    //提现按钮
    withdrawns() {
      if (this.money != '') {
        if (this.money < this.minMoney) {
          Toast(this.placeholders)
        }
        else if ((this.money * 100000) / this.exchange.exchangeRatio > this.familyIncomeData.allPocket) {
          Toast(this.$t('familyIncome.balance_insufficient'))
        }
        else {
          this.familyWithdraw()
        }
      }
      else {
        Toast(this.placeholders)
      }
    },

    //帮助按钮
    clickHelp() {
      this.exchangeShow = true
      $(".guanbiShow").fadeIn(150);
    },
    //关闭按钮
    guanbi() {
      this.exchangeShow = false
      $(".guanbiShow").fadeOut();
    },
  },

};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */
html[lang="ar"] .text-right {
  direction: rtl;
}

html[lang="ar"] .earnings img {
  margin-left: 0.1rem;
}

html[lang="ar"] .Coins img {
  margin-right: 0.12rem;
}

html[lang="ar"] .help {
  margin-right: 0.13rem;
}

html[lang="ar"] .dollar {
  margin-right: 0.27rem;
  margin-left: 0;
}

html[lang="ar"] .van-field__control {
  text-align: right;
}

html[lang="ar"] .dian {
  margin: 0.12rem 0 0.6rem 0.07rem;
}

html[lang="ar"] .bindingleft {
  margin-left: 0.15rem;
  margin-right: 0;
}

html[lang="ar"] .bindingright {
  margin-left: 0;
  margin-right: 0.15rem;
}

.flexs {
  display: flex;
  align-items: center;
}

.urlfmIncome {
  /* width: 100%; */
  /* height: 2.39rem; */
  background-image: url("../../assets/familyIncome/Earnings_this_weekbg.png");
  background-size: 100% 100%;
  padding: 0.27rem;
  box-sizing: border-box;
}

.earnings {
  display: flex;
  align-items: center;
  font-family: mediums;
  font-size: 0.25rem;
}

.earnings img {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.1rem;
  position: relative;
}

.frozen {
  font-size: 0.188rem;
  position: absolute;
  color: #000000;
  font-family: Regular;
  margin-top: 0.1rem;
  line-height: 1;
  display: flex;
  align-items: center;
}

.Coins {
  color: #000000;
  text-align: center;
  margin-top: 0.5rem;
}

.Coins img {
  width: 0.34rem;
  height: 0.34rem;
  margin-left: 0.12rem;
}

.thisWeekCoins {
  font-size: 0.478rem;
  font-family: Semibold;
}

.extractable {
  font-family: Regular;
  font-size: 0.188rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titlebox {
  display: flex;
  margin-bottom: 0.4rem;
}

.dian {
  width: 0.07rem;
  height: 0.07rem;
  background: #5dc48a;
  border-radius: 50%;
  min-width: 0.07rem;
  min-height: 0.07rem;
  margin: 0.12rem 0.07rem 0.6rem 0;
}

.titleb {
  font-size: 0.27rem;
  font-family: mediums;
  font-weight: 400;
  color: #000000;
  line-height: 1;
  /* padding: 0.245rem 0 0 0; */
}

.rule {
  font-size: 0.23rem;
  color: #707480;
  line-height: 0.34rem;
  padding-bottom: 0.35rem;
  font-family: Regular;
}

.Withdraw {
  background: #5dc48a;
  color: #ffffff;
  font-size: 0.27rem;
  border-radius: 50px;
  padding: 0.28rem 0;
  text-align: center;
  line-height: 1;
  position: fixed;
  bottom: 0.837rem;
  display: flex;
  justify-content: center;
  left: 16px;
  right: 16px;
}

.van-toast__text {
  font-family: Regular;
  font-size: 0.23rem;
}

.van-popup--bottom.van-popup--round {
  border-radius: 25px 25px 0 0;
  background-color: #fafafa;
}

.application {
  font-size: 0.29rem;
  font-family: mediums;
  color: #000000;
  margin-bottom: 0.6rem;
  text-align: center;
  line-height: 1;
}

.proportion {
  color: #666666;
  font-size: 0.2rem;
  /* line-height: 1; */
  display: flex;
  align-items: center;
}

.help {
  width: 0.23rem;
  height: 0.23rem;
  margin-left: 0.13rem;
}

.paymentMethod {
  background: #eeeeee;
  border-radius: 0.13rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 0.76rem;
  width: 100%;
}

.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background: none;
}

.van-field__control {
  color: #000000;
  font-family: Regular;
  font-size: 0.22rem;
}

.van-field__control::-webkit-input-placeholder {
  color: #b0b6b6;
  font-family: Regular;
  font-size: 0.22rem;
}

.deduct {
  color: #666666;
  font-size: 0.188rem;
  font-family: Regular;
  line-height: 1;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}

.confirm {
  margin-top: 0.598rem;
  border-radius: 25px 25px 0 0;
  background: #fff;
  padding: 0.44rem 16px 0.58rem;
}

.withdrawn {
  font-size: 0.256rem;
  color: #000000;
  font-family: mediums;
}

.withdrawns {
  position: relative;
  bottom: auto;
  left: 0;
  right: 0;
  margin-top: 0.7rem;
}

.Successfultitle {
  color: #000000;
  font-family: mediums;
  font-size: 0.3rem;
}

.Successful {
  color: #b0b6b6;
  font-family: Regular;
  font-size: 0.22rem;
  line-height: 0.33rem;
  margin-top: 0.17rem;
}

.Exchangetitle {
  font-family: mediums;
  color: #000000;
  font-size: 0.27rem;
  text-align: center;
}

.Exchangebox {
  font-size: 0.2rem;
  background: #f6f7fb;
}

.biaotou {
  width: 100%;
  height: 0.93rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0.25rem 0 0;
  border-bottom: 1px dashed #666666;
}

.biaotou > .titles {
  font-size: 0.2rem;
  font-family: mediums;
  font-weight: 500;
  color: #000000;
  width: 3rem;
  padding-left: 0.1rem;
  text-align: center;
}

.biaot_content {
  width: 100%;
  padding: 0.32rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 0.27rem;
  font-family: mediums;
  font-weight: 400;
  color: #666666;
}

.tips {
  color: #666666;
  font-family: Regular;
  font-size: 0.188rem;
  margin: 0.196rem 0.34rem 0.15rem;
}

.exchangeShow {
  /* display: flex;
    justify-content: center; */
  background: none;
}

.exchangeShowbox {
  padding: 0.29rem 0 0.19rem;
  width: 90%;
  background: #fff;
  border-radius: 25px;
  position: relative;
  margin: auto;
}

.guanbi {
  /* position: absolute; */
  bottom: 10%;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 0.478rem;
  height: 0.478rem;
}

.dollar {
  font-size: 0.256rem;
  margin-left: 0.27rem;
}

.binding {
  background: #5dc48a;
  color: #ffffff;
  font-size: 0.27rem;
  border-radius: 50px;
  padding: 0.28rem 0;
  text-align: center;
  line-height: 1;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: auto;
  left: 0;
  right: 0;
  margin: 0.4rem 0.15rem 0;
  padding: 0.33rem 0;
  width: 50%;
}

.bindingleft {
  margin-left: 0;
  background: #f8f8f8;
  color: #666666;
}

.bindingright {
  margin-right: 0;
}
</style>